@import '../../_variables';
@import '../../element/_expandable';

.header-account {
  display: flex;
  justify-content: flex-end;
  z-index: @max-z-index;
  .expandable(10rem, 2rem, auto);
}

//todo remover ao corrigir o StorePage
.header-account-2 {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  z-index: @max-z-index;
  top: 0;
  right: 2rem;
  .expandable(10rem, 2rem, 12.5rem);

  .header-account-menu-list li {
    padding: .5rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid @color-default-2;
    }
  }
}
