body {
  --color-white: #FFFFFF;
  --color-black: #000000;

  //    --color-default-1: #E0D8C1; // todo
  //    --color-default-2: #C4B487;
  //    --color-default-3: #8F8058;
  //    --color-default-4: #6E4A3A;
  //    --color-default-5: #231F20;

  //    --color-default-text-1: #E0D8C1; // todo
  //    --color-default-text-2: #C4B487;
  //    --color-default-text-3: #8F8058;
  //    --color-default-text-4: #6E4A3A;
  //    --color-default-text-5: #231F20;

  --color-default-1: #EDEDED;
  --color-default-2: #E4E4E4;
  --color-default-3: #828282;
  --color-default-4: #646464;
  --color-default-5: #ededed;

  --color-default-text-1: #EDEDED;
  --color-default-text-2: #E4E4E4;
  --color-default-text-3: #828282;
  --color-default-text-4: #646464;
  --color-default-text-5: #2E2C29;

  --color-primary-1: #b0b0b0; // todo
  --color-primary-2: #B0B0B0;
  --color-primary-3: #B0B0B0;
  --color-primary-4: #999999;
  --color-primary-5: #B0B0B0;

  --color-info-1: #b0b0b0; // todo
  --color-info-2: #307a9f;
  --color-info-3: #307a9f;
  --color-info-4: #307a9f;
  --color-info-5: #490017;

  --color-success-1: #44ADE2;
  --color-warning-1: #ffd64f;
  --color-danger-1: #EF404A;
  --color-danger-5: #2e2c29;
}
