@font-face {
  font-family: 'Classic Sans';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/classic-sans/ClassicSansLight.otf") format("opentype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Classic Sans';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/classic-sans/ClassicSansRoman.otf") format("opentype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Classic Sans';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/classic-sans/ClassicSansBold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Lektorat Condensed';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/lektorat-condensed/LektoratCondensedExtrabold.otf") format("opentype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Classic Sans';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/classic-sans/ClassicSansLight-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Classic Sans';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/classic-sans/ClassicSansRoman-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Classic Sans';
  src: url("https://d2mvzkgjbebhwi.cloudfront.net/fonts/classic-sans/ClassicSansBold-Italic.otf") format("opentype");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}
