@import '../../_variables';
@import '_header-variables';

.header {
  .header-scroll {
    height: 48px !important;
    transition: height 300ms;
  }

  #header-desktop& {
    border-bottom: solid 1px @color-default-5;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
    opacity: 0;
  }

  #header-topbar-dropdown {
    display: inline-grid;

    .div-sidebar {
      box-shadow: @shadow-3;
    }

    .sidebar {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    .logout {
      color: @color-black !important;

      &:hover {
        color: @color-danger-1 !important;
      }
    }

    p {
      line-height: 1.2;
    }

    a {
      text-decoration-line: none;
      color: @color-black;

      &:hover {
        color: @color-default-text-3;
      }
    }
  }

  .header-topbar-wrapper {
    background-color: @header-bg-color-2;
    z-index: 1;
    box-shadow: @default-shadow;
    transition: box-shadow @transition-duration @transition-curve, transform .2s @transition-curve;

    #header-topbar {
      height: @header-topbar-height-xs;
    }

    @media (@max-screen-sm) {
      padding-top: 10px;
    }
  }

  a.header-product-title {
    color: @header-text-color;

    &:hover {
      text-decoration: none;
    }
  }

  .header-product-title-badge {
    color: @color-primary-5;
    font-size: .55em;
    margin-left: .25rem;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.25;

    @media (@min-screen-sm) {
      line-height: 1.5;
    }
  }

  .header-desktop-nav-frame {
    border-radius: @radius-default @radius-default 0 0;
    background-color: @header-bg-color-2;

    &.open {
      background-color: @color-default-1;

      a.header-product-title {
        color: @color-default-5;

        .open {
          color: @color-primary-5;
        }
      }
    }
  }

  #header-desktop-detail-wrapper {
    position: absolute;
  }

  &.categories-slide-enabled {
    #header-desktop-nav {
      position: fixed;
      top: calc(@header-compact-height - @header-topfeature-height); // altura da barra principal - da barra de produtos
      width: 100vw;
      transition: top .25s @transition-curve;
      background-color: @header-bg-color-1;
    }

    #header-desktop-detail-wrapper {
      position: fixed;
      top: calc(@header-topfeature-height + @header-compact-height); // altura da barra de produtos + barra principal
    }

    #header-desktop-logo-wrapper .icon-chevron-down {
      opacity: 1 !important;
    }

    &.do-slide {
      .header-topbar-wrapper {
        box-shadow: none;
      }

      #header-desktop-nav {
        top: @header-compact-height; // altura da barra principal
      }

      #header-desktop-logo-wrapper .icon-chevron-down {
        opacity: 0 !important;
      }
    }
  }
}

@media (@min-screen-sm) {
  .header {
    .header-topbar-wrapper {
      z-index: 3;
      box-shadow: none;

      #header-topbar {
        height: @header-topbar-height-sm;
        transition: height 300ms;
      }
    }

    &.shadow #header-desktop-nav {
      box-shadow: @default-shadow;
    }
  }
}
