@import '../_variables';

.logo() {
  background-color: currentColor;
  mask-size: 100%;
  mask-repeat: no-repeat;
}

.logo-up-penca {
  .logo();
  mask-image: url("@{cdn}/universal/logo/mais-uni-logo-ilustracao.png");
  mask-position: center;
}

.logo-up-full-h {
  .logo();
  mask-image: url("@{cdn}/universal/mais-uni-logo.png");
  mask-position: left 50%;
}

.logo-up-full-v {
  .logo();
  mask-image: url("@{cdn}/universal/logo/uma-penca-full-v.svg");
  mask-position: center;
}

.logo-up-text {
  .logo();
  mask-image: url("@{cdn}/universal/mais-uni-logo.png");
  mask-position: left 50%;
}
