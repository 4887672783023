/* https://github.com/verlok/lazyload#dealing-with-microsoft-edge-problems */
img[data-src],
img[data-srcset] {
  display: block;
  min-height: 1px;
}

/* Prevents img without src to appear */
img:not([src]) {
  visibility: hidden;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

img.lazy.lazy-fade {
  opacity: 0;
  transition: opacity .5s @transition-curve;

  &.loaded {
    opacity: 1;
  }

  &.size {
    height: 4rem !important;
  }
}

figure {
  margin: 0;
}